/* Analytics Container */
.analytics-container {
    margin: 0 0 20px;
    padding: 5px 10px;
    border-radius: 24px;
    background: #d2eaff;
    /* Light blue background */
}

.analytics-subcontainer {
    border-top: #1a1a1a60 solid 1px;
}

/* Header for the Analytics Section */
.analytics-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.analytics-header h4 {
    margin: 0;
    padding: 5px 10px;
    color: #1a1a1a;
}

/* Chart container - makes charts appear side by side */
.analytics-charts {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 30px;
    /* Increase spacing between charts */
    margin-top: 20px;
}

/* Individual chart container */
.analytics-chart {
    flex: 1;
    min-width: 400px;
    /* Make charts larger */
    max-width: 50%;
    /* Allow them to take equal space */
    text-align: center;
    font-size: 90% !important;
}

/* Adjust Pie Chart Legend */
.pie-chart .recharts-legend-wrapper {
    right: 0 !important;
    /* Move legend to the right */
    left: auto !important;
    top: 50% !important;
    transform: translateY(-50%);
    font-size: 90% !important;
}

/* Responsive for tablets: Stacks charts */
@media (max-width: 1024px) {
    .analytics-charts {
        flex-wrap: wrap;
        justify-content: center;
    }

    .analytics-chart {
        max-width: 80%;
    }
}

/* Responsive for smaller screens: One chart per row */
@media (max-width: 768px) {
    .analytics-charts {
        flex-direction: column;
        align-items: center;
    }

    .analytics-chart {
        max-width: 100%;
    }
}

/* Responsive for mobile: One chart per row */
@media (max-width: 480px) {
    .analytics-charts {
        flex-direction: column;
        align-items: center;
    }

    .analytics-chart {
        max-width: 100%;
    }
}

/* Responsive for mobile: One chart per row */
@media (max-width: 320px) {
    .analytics-charts {
        flex-direction: column;
        align-items: center;
    }

    .analytics-chart {
        max-width: 100%;
    }
}